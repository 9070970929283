.pdf-container {
}

.pdf-container .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdf-container .react-pdf__Page {
}

.pdf-container .react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}

.pdf-container .react-pdf__Page__textContent {
  max-width: 100%;
  height: auto !important;
}

.pdf-container .react-pdf__Page__annotations {
  max-width: 100%;
  height: auto !important;
}
